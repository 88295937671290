<template>
    <div class="addGroup">
        <div class="form-group">
            <label>{{ labelName.name }}</label>
            <el-input v-model="form.name"></el-input>
        </div>
        <div class="form-group">
            <label>{{ labelName.code }}</label>
            <el-input v-model="form.code" :disabled="extr.handleType===2"></el-input>
        </div>
        <template v-if="extr.type===2||extr.type===3||extr.type===4">
            <div class="form-group">
                <label>字典编码</label>
                <el-input v-model="form.dic_code"></el-input>
            </div>
            <div class="form-group">
                <label>字典类型</label>
                <div>
                    <el-radio-group v-model="form.is_system_dic">
                        <el-radio :label="0">
                            普通字典
                        </el-radio>
                        <el-radio :label="1">
                            系统字典
                        </el-radio>
                    </el-radio-group>
                </div>
            </div>
        </template>
        <div class="tips">
            <div class="tit">
                <span class="iconfont icontishi"></span>
                <span class="text">编码规则：业务分组编码采用4组进行编码，每组之间用“-”隔开</span>
            </div>
            <div class="info">
                <p>1.  第1位是业务模块，采用F（分组的意思）+1位数字表达，如“生产管理”的编码是“F3-”，数字与数据字典中尽量保持一致。</p>
                <p>2. 第2位是业务分组，就是根据业务需要建立的分组，采用2位数字表达，如“生产管理-料仓分类”的编码是“F3-01-”。</p>
                <p>3. 第3位是业务子类，采用业务常用的汉字首字母或英文大写字母表达，如“生产管理-料仓分类-物理料仓”的编码是“F3-01-WL-”。</p>
                <p>4. 第4位是分组明细，就是在业务分组中最终要使用的名称，如“生产管理-料仓分类-物理料仓-水泥”的编码是“F3-01-WL-01”</p>
            </div>
        </div>
        <div class="btn-group">
            <span class="sure-btn" @click="handleSubmit">确定</span>
            <span class="cancel-btn" @click="handleCancel">取消</span>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            labelName: {
                name: '',
                code: '',
            },
            form: {
                name: '',
                code: '',
                dic_code: '',
                is_system_dic: 0,
            },
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 新增
        handleSubmit() {
            if (!this.form.name) {
                this.$message.warning('名称不能为空!');
                return;
            }
            if (!this.form.code) {
                this.$message.warning('编码不能为空!');
                return;
            }
            let data = {};
            if (this.extr.type === 2 || this.extr.type === 3 || this.extr.type === 4) {
                // if (!this.form.dic_code) {
                //     this.$message.warning('字典编码不能为空!');
                //     return;
                // }
                // if (!this.form.is_system_dic) {
                //     this.$message.warning('请选择字典类型!');
                //     return;
                // }
                if (this.extr.handleType === 1) {
                    data = {
                        parent_code: this.extr.parent_code,
                        code: this.form.code,
                        name: this.form.name,
                        dic_code: this.form.dic_code,
                        is_system_dic: this.form.is_system_dic,
                    };
                } else {
                    data = {
                        code: this.form.code,
                        name: this.form.name,
                        dic_code: this.form.dic_code,
                        is_system_dic: this.form.is_system_dic,
                    };
                }

            } else {
                // eslint-disable-next-line no-lonely-if
                if (this.extr.handleType === 1) {
                    data = {
                        parent_code: this.extr.parent_code,
                        code: this.form.code,
                        name: this.form.name,
                    };
                } else {
                    data = {
                        code: this.form.code,
                        name: this.form.name,
                    };
                }

            }
            if (this.extr.handleType === 1) {
                this.$axios
                    .post('/interfaceApi/baseinfo/businessgroup_config/create', data)
                    .then(res => {
                        if (res) {
                            this.$message.success('添加成功');
                            this.extr.callBackFn();
                            window.hideToast();
                        }
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.$axios
                    .put('/interfaceApi/baseinfo/businessgroup_config/modify', data)
                    .then(res => {
                        if (res) {
                            this.$message.success('修改成功');
                            this.extr.callBackFn();
                            window.hideToast();
                        }
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }

        },
        // 取消
        handleCancel() {
            window.hideToast();
        },
    },
    created() {
        const type = this.extr.type;
        switch (type) {
            case 1:
                this.labelName.name = '业务模块名称：';
                this.labelName.code = '业务模块编码：';
                break;
            case 2:
                this.labelName.name = '业务分组名称：';
                this.labelName.code = '业务分组编码：';
                break;
            case 3:
                this.labelName.name = '分组子类名称：';
                this.labelName.code = '分组子类编码：';
                break;
            default:
                this.labelName.name = '分组枚举明细名称：';
                this.labelName.code = '分组枚举明细编码：';
                break;
        }
        const handleType = this.extr.handleType;
        if (handleType === 2) {
            const node = JSON.parse(JSON.stringify(this.extr.node));
            this.form = node;
        } else {
            // this.form.is_system_dic = 0;
        }
    },
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.addGroup
    padding .3rem .4rem
    .form-group
        overflow hidden
        margin-bottom .2rem
        label
            width 1.5rem
            float left
            line-height .36rem
            height .36rem
        .el-input
            width calc(100% - 1.5rem)
            float left
    .tips
        font-size .13rem
        margin-top .4rem
        .tit
            .icontishi
                color #EB656F
                font-size .13rem
                margin-right .1rem
            .text
                color #333333
        .info
            margin-top .1rem
            color #979EA7
            p
                line-height .24rem
    .btn-group
        position absolute
        left 0
        bottom .2rem
        width 100%
        text-align center
        .sure-btn
            width 1.8rem
            height .4rem
            background rgba(21,119,210,1)
            color #ffffff
            text-align center
            line-height .4rem
            display inline-block
            margin-right .2rem
            cursor pointer
        .cancel-btn
            width 1.8rem
            height .4rem
            border 1px solid rgba(21,119,210,1)
            color rgba(21,119,210,1)
            text-align center
            line-height .4rem
            display inline-block
            box-sizing border-box
            cursor pointer
</style>